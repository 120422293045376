import React, { FC, useEffect } from 'react';
import { createUseStyles } from 'react-jss';

import { autorun } from 'mobx';
import { observer } from 'mobx-react';

import { tryParsingBackendError } from '@frontend-monorepo/http-client';

import { useRootStore } from '../../../hooks';
import pageRedirect from '../../../services/page-redirect';

import Titles from './Titles/Titles';
import LoginOptions from './LoginOptions';

const useStyle = createUseStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',

    '& .CyoloUI-BaseCard': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
});

const MfaScreen: FC = () => {
  const {
    uiStore,
    stateStore: { mfaScreenState },
  } = useRootStore();

  useEffect(() => {
    mfaScreenState.startup();
    return () => {
      mfaScreenState.teardown();
    };
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const policyID = queryParams.get('policy_id');
    if (!policyID) {
      return;
    }
    mfaScreenState.setPolicyId(policyID);
  }, []);

  useEffect(() => {
    return autorun(() => {
      if (!mfaScreenState.shouldPerformPageRedirect) return;
      pageRedirect.performPageRedirect();
    });
  }, []);

  useEffect(() => {
    // after any change of the verification state make sure to fetch new auth data
    return autorun(async () => {
      if (mfaScreenState.mfaCodeValidationState !== 'idle') return;

      try {
        await mfaScreenState.fetchMfaData();
      } catch (e) {
        uiStore.showToast(
          tryParsingBackendError(e, 'failed to fetch user mfa data'),
          'refused',
        );
      }
    });
  }, []);

  const style = useStyle();

  return (
    <div className={style.root}>
      <Titles />
      <LoginOptions />
    </div>
  );
};

export default observer(MfaScreen);
