import Bugsnag from '@bugsnag/js';
import ms from 'ms';

import { IReactionDisposer, makeAutoObservable, runInAction, when } from 'mobx';

import { MfaId, MfaMethod } from '@frontend-monorepo/cyolo-auth';
import { Store } from '@frontend-monorepo/cyolo-store';
import {
  Optional,
  Validator as validator,
} from '@frontend-monorepo/cyolo-utils';

import { AppRoute } from '../../routes';
import GeneralAPI from '../../services/api/api';
import AuthAPI from '../../services/api/auth';
import { TTotpOrigin } from '../../services/api/auth/api';
import DataStoreContainer from '../data/data';
import LocationStore from '../location-store';
import RequestIntervalStore from '../shared/request-interval-store';

import { StoreTransactionState } from './types';

interface WithRemainingTime {
  remainingTime: number;
}

const hasRemainingTime = (obj: unknown): obj is WithRemainingTime => {
  return (
    (obj as WithRemainingTime)?.remainingTime !== undefined &&
    typeof (obj as WithRemainingTime).remainingTime === 'number'
  );
};

const DefaultExternalMFAState: Record<
  MfaId,
  { sentOnce: boolean; magicLink: boolean }
> = {
  [MfaId.EMAIL]: {
    sentOnce: false,
    magicLink: false,
  },
  [MfaId.SMS]: {
    sentOnce: false,
    magicLink: false,
  },
  [MfaId.TOTP]: {
    sentOnce: false,
    magicLink: false,
  },
};

class MfaScreenState {
  constructor(
    private dataStores: DataStoreContainer,
    private locationStore: LocationStore,
    public smsRequestStore = new RequestIntervalStore(),
    public emailRequestStore = new RequestIntervalStore(),
  ) {
    this.digitsInputValue = {};

    this.smsSentState = 'idle';

    this.mfaCodeValidationState = 'idle';

    this.isSupervised = false;

    this.userEmail = '';

    makeAutoObservable(this, {}, { autoBind: true });
  }

  activeIntervalID: number | undefined = undefined;

  // used for cleaning up reactions
  disposables: IReactionDisposer[] = [];

  // tabSelection is the selected mfa method id
  tabSelection?: MfaId;
  isSupervised: boolean;
  userEmail: string;

  // used to manage the state of different external mfa providers
  externalMFAState = DefaultExternalMFAState;

  // query params
  policyId: Optional<string>;

  get isMagicLinkEnabled(): boolean {
    return this.tabSelection
      ? this.externalMFAState[this.tabSelection].magicLink
      : false;
  }

  /**
   * indicates if we can already know what external MFA type
   * was sent to the user (magic link or OTP) based on the
   * current tab selection
   */
  get canExternalMFATypeBeInferred(): boolean {
    return this.tabSelection
      ? this.externalMFAState[this.tabSelection]?.sentOnce
      : false;
  }

  get loginSubtitle(): string {
    if (this.isSupervised) {
      return 'Waiting approval';
    }

    if (this.userMethods.length === 0) {
      return 'There are no MFA methods available for your account';
    }

    return 'Please verify your account';
  }

  get verificationBodyText(): string {
    if (this.isSupervised) {
      return 'Supervisor has been requested to approve your access';
    }

    if (this.userMethods.length === 0) {
      return 'Please contact your administrator to reset your account';
    }

    switch (this.tabSelection) {
      case MfaId.TOTP:
        return `Please confirm your account by entering 
        the 6-digit code from your MFA app or from the message sent to you`;
      case MfaId.SMS:
        return 'Please confirm your account by entering the 6-digit code sent to your mobile phone';
      case MfaId.EMAIL:
        return `Please confirm your account by entering 
        the 6-digit code sent to your email`;
      default:
        return '';
    }
  }

  get shouldPerformPageRedirect(): boolean {
    const mfaStore = this.dataStores.mfaDataStore;

    if (mfaStore.data) return true;

    if (mfaStore.state !== 'done') return false;

    return mfaStore.data;
  }

  get mfaDataStore(): Store<boolean> {
    return this.dataStores.mfaDataStore;
  }

  async fetchMfaData(): Promise<void> {
    this.dataStores.mfaDataStore.data = await GeneralAPI.didUserPassMfa(
      this.policyId,
    );
    return;
  }

  resetVerificationState(): void {
    this.digitsInputValue = {};
    this.mfaCodeValidationState = 'idle';
  }

  // selectedMfaMethod returns you the current active mfaMethod based in the active tab selection
  get selectedMfaMethod(): MfaMethod | undefined {
    return this.dataStores.userMfaMethodsStore.data.find(
      (method) => method.id === this.tabSelection,
    );
  }

  get selectedMfaMethodID(): number {
    return this.userMethods.findIndex(
      (method) => method.id === this.selectedMfaMethod?.id,
    );
  }

  get userMethods() {
    return this.dataStores.userMfaMethodsStore.data;
  }

  // digitsInputValue is the raw object holding the digitsInputValue of all tabs
  digitsInputValue: { [key: string]: string };

  // currentDigitsInputValue gives you the current active tab digits input value
  get currentDigitsInputValue(): string {
    return this.tabSelection ? this.digitsInputValue[this.tabSelection] : '';
  }

  // updateDigitsInputValue updates the active tab digitsInputValue
  updateDigitsInputValue(change: string): void {
    // validate that the change given is a valid number
    if (isNaN(+change) || !this.tabSelection) {
      return;
    }

    this.digitsInputValue[this.tabSelection] = change;
  }

  // mfaCodeValidationState is the store transaction state for the mfa code validation
  mfaCodeValidationState: StoreTransactionState;

  // handleDigitsInputCompletion is a handler function for finishing mfa code verification
  async handleDigitsInputCompletion(
    code: unknown,
    origin: TTotpOrigin,
  ): Promise<void> {
    if (!this.selectedMfaMethod) return;

    this.mfaCodeValidationState = 'in-work';

    try {
      await AuthAPI.postTotpConfirmationCode(code, origin, this.policyId);
      await this.dataStores.authDataStore.fetch();
    } finally {
      runInAction(() => {
        this.mfaCodeValidationState = 'idle';
      });
    }
  }

  get email() {
    return this.userEmail;
  }

  setEmail(text: string) {
    this.userEmail = text;
  }

  setPolicyId(id: string | undefined) {
    this.policyId = id;
  }

  get isSendMfaEmailEnabled() {
    const validEmail = validator.email(this.userEmail);

    return validEmail && this.emailRequestStore.isTimeoutOver;
  }

  async sendMfaCodeByEmail() {
    try {
      if (!this.emailRequestStore.isTimeoutOver) {
        return;
      }

      const res = await AuthAPI.postRequestExternalMFACode(
        MfaId.EMAIL,
        this.policyId,
      );

      runInAction(() => {
        this.emailRequestStore.updateTimeDelta(res.remainingTime);
        this.externalMFAState[MfaId.EMAIL] = {
          sentOnce: true,
          magicLink: res.magicLink,
        };
      });
      return res;
    } catch (err) {
      if (hasRemainingTime(err)) {
        const { remainingTime } = err;
        // if got remaining time update it
        if (remainingTime) {
          runInAction(() => {
            this.emailRequestStore.updateTimeDelta(remainingTime || 0);
          });
        }
      }

      throw err;
    }
  }

  async enrollMfaEmail() {
    const res = await AuthAPI.postEmailSubmission(this.email);

    runInAction(() => {
      this.emailRequestStore.updateTimeDelta(res.remainingTime);
    });

    return res;
  }

  // sms
  async sendMfaCodeBySMS() {
    try {
      if (!this.smsRequestStore.isTimeoutOver) {
        return;
      }

      const res = await AuthAPI.postRequestExternalMFACode(
        MfaId.SMS,
        this.policyId,
      );

      runInAction(() => {
        this.smsRequestStore.updateTimeDelta(res.remainingTime);
        this.externalMFAState[MfaId.SMS] = {
          magicLink: res.magicLink,
          sentOnce: true,
        };
      });

      return res;
    } catch (err) {
      // if got remaining time update it
      if (hasRemainingTime(err)) {
        const { remainingTime } = err;
        runInAction(() => {
          this.smsRequestStore.updateTimeDelta(remainingTime);
        });
      }

      throw err;
    }
  }

  // smsSentState is the state of the auto sms sent transaction
  smsSentState: StoreTransactionState;

  get showSmsSendButton(): boolean {
    return this.selectedMfaMethod?.id === MfaId.SMS;
  }

  // canSendSms is a boolean value of wheter the current fullPhoneNumber
  // is a valid phone number
  get canSendSms(): boolean {
    // if the state is not idle return false
    if (this.mfaCodeValidationState === 'in-work') return false;

    // if the state is not idle return false
    if (this.smsSentState === 'in-work') return false;

    // if sms timeout is not over return false
    if (!this.smsRequestStore.isTimeoutOver) return false;

    return true;
  }

  // totp

  selectTab(tabId: MfaId): void {
    this.tabSelection = tabId;
  }

  startup() {
    // when the auth store organization methods are not empty, set the first method to be the tab selection
    const firstMethodWhenDispose = when(
      () => this.dataStores.userMfaMethodsStore.data.length !== 0,
      () => {
        this.tabSelection = this.dataStores.userMfaMethodsStore.data[0].id;
      },
    );

    const userStoreDisposer = when(
      () => this.dataStores.userStore.state === 'done',
      () => {
        this.userEmail = this.dataStores.userStore.data?.email;
        this.isSupervised = Boolean(
          this.dataStores.userStore.data.supervisor?.enabled,
        );
      },
    );

    // wating for supervisor access. we send code request by supervisor supported method
    const superviseInitCallDisposer = when(
      () =>
        this.dataStores.userMfaMethodsStore.state === 'done' &&
        this.dataStores.userStore.state === 'done',
      () => {
        const targetMfa = this.dataStores.userMfaMethodsStore.data.map(
          (i) => i.id,
        )[0];
        if (this.isSupervised) {
          switch (targetMfa) {
            case MfaId.SMS:
              this.sendMfaCodeBySMS();
              break;
            case MfaId.EMAIL:
              this.sendMfaCodeByEmail();
              break;
            default:
              Bugsnag.notify(
                `[MfaScreenState] no supervisor method match for user. userMfa=${targetMfa}`,
              );
              break;
          }
        }
      },
    );

    this.disposables.push(
      firstMethodWhenDispose,
      userStoreDisposer,
      superviseInitCallDisposer,
    );

    // fetches data on a given interval in cases where
    // an SMS msg is sent to the user as a magic link
    //TODO: can be refactored
    this.activeIntervalID = window.setInterval(() => {
      // check that the route is MFA
      if (this.locationStore.currentAppRoute !== AppRoute.Mfa) return;

      if (this.isExplicitAuthPollingEndpointDefined) {
        // polling for mfa data for supervisor approval
        this.fetchMfaData();
      }
    }, ms('2s'));

    this.externalMFAState = DefaultExternalMFAState;
  }

  get isExplicitAuthPollingEndpointDefined() {
    return Boolean(sessionStorage.getItem('auth_data_polling_endpoint'));
  }

  teardown() {
    sessionStorage.removeItem('auth_data_polling_endpoint');
    this.disposables.forEach((dispose) => dispose());
    this.activeIntervalID && clearInterval(this.activeIntervalID);
  }

  // email field is disabled if there's existing email to the user upon enrollment.
  get isEmailFieldDisabled(): boolean {
    return Boolean(this.dataStores.userStore?.data?.email);
  }
}

export default MfaScreenState;
