import { UnknownValue } from '@frontend-monorepo/cyolo-store';

import { GeneralIdpObject } from '../idp';

export enum UserAttributeName {
  AcceptedLegalDocumentation = 'accepted_legal_doc',
}

export interface UserAttributeObject extends UnknownValue {
  attribute_id: string;
  attribute_name: UserAttributeName;
}

export interface IBaseGroupObject {
  id: string;
  name: string;
  enabled: boolean;
}

export interface IUserObject extends IBaseUserObject {
  id: string;
  kind: string;
  system?: boolean;
  authenticated: boolean;
  totp_enabled: boolean;
  sms_enrolled: boolean;
  email_enrolled: boolean;
  allowed_idps: GeneralIdpObject[];
  supervisor: IUserObject | null;
  last_login: string | null;
  labels: string[];
  personal_desktop: string;
  attributes: Array<UserAttributeObject> | null;
  mfa_valid_policies: string[];
  last_logout: string;
  simple_groups: IBaseGroupObject[];
  external_groups: IBaseGroupObject[];
}

export interface IBaseUserObject {
  name: string;
  enabled: boolean;
  email: string;
  phone_number: string;
  enrolled: boolean;
  totp_enrolled: boolean;
  password?: string;
  must_change_password?: boolean;
  send_email?: boolean;
  totp_secret?: string;
  invite_user?: boolean;
  first_name: string;
  last_name: string;
  email_enrolled?: boolean;
  sms_enrolled?: boolean;
}

export interface IUpdatableUserObject extends IBaseUserObject {
  allowed_idps: number[];
  supervisor: number | null;
  personal_desktop: string[];
}

export type IMultipleUsersResponse = IUserObject[];
