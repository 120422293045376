import { HttpStatusCode } from '@frontend-monorepo/cyolo-store';
import httpClient from '@frontend-monorepo/http-client';

import { AppUrls } from '../../model';
import { ILegalDoc } from '../response';

import Endpoints from './endpoints';
import {
  Approval,
  ApprovalState,
  PendingApproval,
  PendingApprovalResponse,
  SupervisorApprovalTokenResponse,
  ValidationError,
} from './response';

interface IAppUrlsObject {
  users_console: string;
}

/**
 * get urls for different apps
 * in the system from the backend
 * @returns
 */
const getAppUrls = async () => {
  const {
    data: { users_console },
  } = await httpClient.get<IAppUrlsObject>('/v1/urls');

  return new AppUrls(users_console);
};

/**
 * perform a username & password login against
 * a given url of a backend service
 * @param url
 * @param username
 * @param password
 * @returns
 */
const postLoginRequest = (url: string, username: string, password: string) => {
  return httpClient.post(
    url,
    { username, password },
    { withCredentials: true },
  );
};

/**
 * fetches approval status for a given approval
 * id from the backend server
 * @param approvalId
 * @returns
 */
const fetchApprovalStatus = async (
  approvalId: string,
): Promise<PendingApproval> => {
  try {
    const { data } = await httpClient.get<PendingApprovalResponse>(
      `/v1/approvals/${approvalId}`,
    );

    return {
      approvalState: data.approval_state,
      expirationTime: data.expiration_time,
      approverReason: data.approver_reason,
      approverName: data.approver_name,
    };
  } catch (err) {
    return {
      approvalState: ApprovalState.rejected,
      expirationTime: new Date().toUTCString(),
      approverReason: '',
      approverName: '',
    };
  }
};

const fetchSupervisorApprovalToken = async (
  token: string,
): Promise<SupervisorApprovalTokenResponse> => {
  const { data } = await httpClient.get<SupervisorApprovalTokenResponse>(
    `/t/${token}`,
  );

  return data;
};

/**
 * notifies the backend a user was shown the
 * supervisor approval screen
 * @param approvalId
 * @returns
 */
const commitPrependingApproval = async (
  approvalId: string,
): Promise<boolean> => {
  try {
    await httpClient.post<void>(`/v1/approvals/${approvalId}/request`);
  } catch (error) {
    return false;
  }
  return true;
};

const didUserPassMfa = async (policyId?: string): Promise<boolean> => {
  let url = `/v1/users/await_approval`;
  if (policyId) {
    url = `${url}/${policyId}`;
  }
  try {
    const { data } = await httpClient.get<Approval>(url);
    return data.is_approved;
  } catch {
    return false;
  }
};

const validationError = async (): Promise<ValidationError | null> => {
  try {
    const { data } = await httpClient.get<ValidationError>(
      Endpoints.validation,
    );
    return data;
  } catch {
    return null;
  }
};

const getLegalDoc = async (): Promise<ILegalDoc> => {
  const { data } = await httpClient.get<ILegalDoc>(Endpoints.legalDoc);
  return data;
};

const approveRequest = async (
  code: string,
  policyId?: string,
): Promise<SupervisorApprovalTokenResponse> => {
  const endpoint = policyId
    ? `${Endpoints.approveRequest}/policy/${policyId}`
    : Endpoints.approveRequest;

  const { data } = await httpClient.post<SupervisorApprovalTokenResponse>(
    `${endpoint}?code=${code}`,
    null,
    {
      validateStatus: (status) => {
        // if we get a 404 we ignore it explicitly
        return (
          status < HttpStatusCode.ClientErrorCodeStart ||
          status === HttpStatusCode.NotFound
        );
      },
    },
  );
  return data;
};

export default {
  commitPrependingApproval,
  fetchApprovalStatus,
  fetchSupervisorApprovalToken,
  postLoginRequest,
  getAppUrls,
  didUserPassMfa,
  validationError,
  getLegalDoc,
  approveRequest,
};
