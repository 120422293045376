import React, { FC, useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';

import { observer } from 'mobx-react';

import {
  Button,
  CyTheme,
  Divider,
  PasswordField,
  Text,
  useThemeSafe,
} from '@frontend-monorepo/cyolo-ui';

import { useRootStore } from '../../../../hooks';
import { AppRoute } from '../../../../routes';
import { parseBackendLoginError } from '../../../../stores/state-store/password-screen-state';
import { useKeyboardPress } from '../../../hooks';
import WelcomeUser from '../../Welcome/WelcomeForm/WelcomeUser';

const ENTER_KEY_CODE = 13;

const PasswordForm: FC = () => {
  const {
    uiStore,
    dataStores,
    stateStore: {
      passwordScreenState: {
        password,
        errorMessage,
        setErrorMessage,
        setPassword,
        isLoginButtonEnabled,
        performFirstPartyLogin,
        performBackAction,
      },
    },
  } = useRootStore();
  const theme = useThemeSafe();
  const styles = useStyles({ theme });

  const confirmPassword = useCallback(async () => {
    try {
      await performFirstPartyLogin();
      uiStore.showToast('Successfully Logged In', 'approved');
    } catch (error) {
      setErrorMessage(parseBackendLoginError(error));
    }
  }, []);

  const onAnotherUserLogin = useCallback(async () => {
    await performBackAction();
  }, [performBackAction]);

  useKeyboardPress(ENTER_KEY_CODE, confirmPassword);

  return (
    <div>
      <div className={styles.passwordContainer}>
        <img src="/public/login-icon.svg" alt="user" />
        <WelcomeUser username={dataStores.userIdentityStore.data.username} />
      </div>
      <div className={styles.userForm}>
        <div className={styles.sectionTitle}>
          <Text variant="table-body">Please enter your password</Text>
        </div>
        <div className={styles.inputFieldWrapper}>
          <div className={styles.inputField}>
            <PasswordField
              id="password-field"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Password"
              autoComplete="on"
            />
          </div>
          {errorMessage && (
            <Text variant="tag" className={styles.errorMsg}>
              {errorMessage}
            </Text>
          )}
        </div>
        <Button
          id="login-button"
          variant="primary"
          className={styles.loginSubmitBtn}
          onClick={confirmPassword}
          disabled={!isLoginButtonEnabled}
        >
          Login
        </Button>
        <Divider
          orientation="horizontal"
          thickness={1}
          className={styles.divider}
        />
        <div className={styles.bottomLinks}>
          <Link
            to={AppRoute.Login}
            className={styles.bottomLink}
            onClick={onAnotherUserLogin}
          >
            <Text variant="body" className={styles.bottomTextStyle}>
              Login as a different user
            </Text>
          </Link>
          {dataStores.userIdentityStore.data.passwordProvider
            ?.passwordPolicy && (
            <Link
              to={`/${AppRoute.RequestResetPassword}`}
              className={styles.bottomLink}
            >
              <Text variant="body" className={styles.bottomTextStyle}>
                Forgot password?
              </Text>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(PasswordForm);

const useStyles = createUseStyles((theme: CyTheme) => ({
  passwordContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '& > *': {
      marginBottom: 8,
    },
  },
  userForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sectionTitle: {
    paddingBottom: 32,
  },
  inputFieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputField: {
    flex: '0 0 auto',
    width: 200,
    marginBottom: 8,
  },
  loginSubmitBtn: {
    width: 200,
  },
  divider: {
    marginTop: 48,
    marginBottom: 16,
    backgroundColor: theme.palette.background.color,
  },
  bottomLinks: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 16,
    width: '100%',
  },
  bottomLink: {
    textDecoration: 'none',
  },
  bottomTextStyle: {
    color: theme.palette.primary.color,
  },
  errorMsg: {
    transition: theme.transitions.fast,
    margin: '0px -8px 8px 0px',
    color: `${theme.palette.error.color} !important`,
    display: 'flex',
    width: 0,
    minWidth: '100%',
  },
}));
